import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
  return {
    type: "DISPOSITIVO_MODO_TELA",
    payload: {
      modo: modo,
      registro: registro,
    },
  };
}
export function initForm(registro = {}) {
  return [initialize("dispositivoForm", registro)];
}

export function getLista() {
  const request = axios.get(`${consts.API_URL}/dispositivo`);
  return {
    type: "DISPOSITIVO_LISTADO",
    payload: request,
  };
}

export function salvar(registro) {
  return (dispatch, getState) => {
    if (!registro.id) {
      axios
        .post(`${consts.API_URL}/dispositivo`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("cadastro"));
          dispatch(
            initForm({
              ...registro,
              ...resp.data,
            })
          );
        })
        .catch((e) => {
          setErroAPI(e);
        });
    } else {
      axios
        .put(`${consts.API_URL}/dispositivo`, {
          ...registro,
        })
        .then((resp) => {
          setSuccess("Operação Realizada com sucesso.");
          dispatch(getLista());
          dispatch(setModoTela("lista"));
        })
        .catch((e) => {
          setErroAPI(e);
        });
    }
  };
}

export function excluir(registro) {
  return (dispatch) => {
    axios
      .delete(`${consts.API_URL}/dispositivo?id=${registro.id}`)
      .then((resp) => {
        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
      })
      .catch((e) => {
        setErroAPI(e);
      });
  };
}
