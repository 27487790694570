import React from 'react';
import Grid from '../layout/grid';
import Select from 'react-select';

export default (props) => (
  <Grid cols={props.cols}>
    <div className="form-group">
      <label htmlFor={props.name}>{props.label}</label>
      {props.onChange ? (
        <Select
          isDisabled={props.readOnly}
          defaultValue={
            props.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter((opt) => opt.value == props.value)[0] || null
              : null
          }
          isClearable={props.isClearable === null || false}
          isSearchable={props.isClearable === null || false}
          onChange={(item) => props.onChange(item ? item.value : null)}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      ) : (
        <Select
          isDisabled={props.readOnly}
          isClearable={props.isClearable === null || false}
          isSearchable={props.isClearable === null || false}
          defaultValue={
            props.input.value
              ? (props.options || [])
                  .map((opt) => ({ value: String(opt.id), label: opt.valor }))
                  .filter((opt) => opt.value == props.input.value)[0] || null
              : null
          }
          onChange={(item) => props.input.onChange(item ? item.value : null)}
          onBlur={(value) => props.input.onBlur()}
          options={(props.options || []).map((opt) => ({
            value: opt.id,
            label: opt.valor,
          }))}
        />
      )}
    </div>
  </Grid>
);
