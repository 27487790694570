import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import Select from "../common/form/select";

import { setModoTela } from "./dispositivoActions";

class DispositivoForm extends Component {
  state = {
    dosadora: {
      quantidade_bombas: 1,
      id_tipo: 3,
    },
  };
  render() {
    return (
      <ContentCard>
        {/* USADOO PARA INFORMAR A QUANTIDADE DE BOMBAS ANTES DE SALVAR A DOSADORA */}
        {this.props.modoTela === "cadastro_dosadora" ? (
          <ContentCardBody style={{ margin: 50 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Select
                cols="12 6 6 6"
                label="Informe a quantidade de bombas da dosadora"
                readOnly={false}
                options={[
                  { id: "1", valor: "1" },
                  { id: "2", valor: "2" },
                  { id: "3", valor: "3" },
                ]}
                isClearable={false}
                isSearchable={false}
                onChange={(value) =>
                  this.setState({ ...this.state, quantidade_bombas: value })
                }
                value={this.state.dosadora.quantidade_bombas}
              />
            </div>

            <Row alignCenter={this.props.salvar}>
              {this.props.salvar ? (
                <Grid cols="6 6 4 3">
                  <Button
                    text="Salvar"
                    event={() => this.props.salvar(this.state.dosadora)}
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                </Grid>
              ) : null}
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardBody>
        ) : (
          // USADO PARA VISUALIZAR OS DADOS ANTES DE EXCLUIR
          <Form event={this.props.handleSubmit}>
            <ContentCardBody>
              <Field
                name="tipo_nome"
                component={LabelAndInput}
                label="Tipo"
                placeholder="Informe o Tipo"
                cols="12 12 12 12"
                readOnly="readOnly"
              />

              <Field
                name="serie"
                component={LabelAndInput}
                label="Série"
                placeholder="Informe a Série"
                cols="12 12 12 12"
                readOnly="readOnly"
              />
              {(this.props.formularioValues || {}).id_tipo == 3 ? (
                <Field
                  name="quantidade_bombas"
                  component={Select}
                  options={[
                    { id: "0", valor: "0" },
                    { id: "1", valor: "1" },
                    { id: "2", valor: "2" },
                    { id: "3", valor: "3" },
                  ]}
                  label="Quantidade de bombas"
                  placeholder="Informe a quantidade de bombas"
                  cols="12 12 12 12"
                  readOnly="readOnly"
                />
              ) : null}
              <>
                <Field
                  name="usuario"
                  component={LabelAndInput}
                  label="Usuário"
                  placeholder="Informe o Usuário"
                  cols="12 12 12 12"
                  readOnly="readOnly"
                />
                <Field
                  name="senha"
                  component={LabelAndInput}
                  label="Senha"
                  placeholder="Informe a Senha"
                  cols="12 12 12 12"
                  readOnly="readOnly"
                />
              </>
            </ContentCardBody>

            <ContentCardFooter>
              <Row alignCenter={this.props.excluir}>
                {this.props.excluir ? (
                  <Grid cols="6 6 4 3">
                    <Button
                      text="Excluir"
                      submit
                      type={"danger"}
                      icon={"fa fa-trash"}
                    />
                  </Grid>
                ) : null}
                <Grid cols="6 6 4 3">
                  <Button
                    text="Voltar"
                    type={"warning"}
                    icon={"fa fa-chevron-left"}
                    event={() => this.props.setModoTela("lista")}
                  />
                </Grid>
              </Row>
            </ContentCardFooter>
          </Form>
        )}
      </ContentCard>
    );
  }
}

DispositivoForm = reduxForm({
  form: "dispositivoForm",
  destroyOnUnmount: false,
})(DispositivoForm);
const mapStateToProps = (state) => ({
  sessao: state.auth.dispositivo,
  registro: state.dispositivo.registro,
  modoTela: state.dispositivo.modoTela,
  formularioValues: getFormValues("dispositivoForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoForm);
